import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

const MBDistributors = () => {


  return (
    <React.Fragment>
      <Helmet title="Scorecard" />
      <Container fluid className="p-0 mco-fade-in">
        <Row>
          <Col lg="12" className="mt-2">
            <iframe
              src="https://minimalartco.metabaseapp.com/public/dashboard/94cb181a-e95a-42fd-9efd-1ec1e546d810"
              frameborder="0"
              width="100%"
              height="3000"
              allowtransparency
            ></iframe>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MBDistributors;
