import { lazy, Suspense } from "react";
// Layouts
import AuthLayout from "./layouts/Auth";
import McoDashboardLayout from "./layouts/McoDashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";

// Dashboards
import McoDashboard from "./pages/minimalart/mb-summary/metabase-index";

// MB Sales
import MBDistributors from "./pages/minimalart/mb-sales/mb-customer";
import MBCustomers from "./pages/minimalart/mb-sales/mb-distributors";

// MB Beneficts
import MBBenefictsSummary from "./pages/minimalart/mb-beneficts/mb-beneficts-summary";
import MBBenefictsBank from "./pages/minimalart/mb-beneficts/mb-beneficts-bank";
import MBBenefictsCorporate from "./pages/minimalart/mb-beneficts/mb-beneficts-corporate";
import MBBenefictsMasive from "./pages/minimalart/mb-beneficts/mb-beneficts-masive";
import MBReports from "./pages/minimalart/mb-reports/mb-reports";
import MBUsersSummary from "./pages/minimalart/mb-users/mb-users-summary";


// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";


// Minimalart routes:
import { DistributorsProvider } from "./contexts/DistributorsContext";
import McoInConstructionPage from "./components/mco/components/McoInConstructionPage";

import { McoDistributorsOverview } from "./pages/minimalart/distributors/McoDistributorsOverview";
import McoCustomersOverview from "./pages/minimalart/customers/McoCustomersOverview";
import { SegmentsOverview } from "./pages/minimalart/products-and-stock/SegmentsOverview";
import { BusinessOverview } from "./pages/minimalart/products-and-stock/BusinessOverview";
import { ProductsProvider } from "./contexts/ProductsContext";
import McoAllProducts from "./pages/minimalart/products-and-stock/McoAllProducts";

import McoDistributors from "./pages/minimalart/distributors/McoDistributors";
import McoDiscounts from "./pages/minimalart/discounts/McoDiscounts";

import McoEmployeesList from "./pages/minimalart/beneficios/benefits-components/McoEmployeesList";
import McoEmployeesOrdersDetails from "./pages/minimalart/beneficios/benefits-components/McoEmployeesOrdersDetails";

import  DistributorPage  from "./pages/minimalart/distributors/charts/DistributorPage";
import BusinessReports from "./pages/minimalart/reports/BusinessReports";

import SimpleReports from "./pages/minimalart/reports/SimpleReports";
import BuyXgetYReports from "./pages/minimalart/reports/BuyXgetYReports";
import { SuperAdminGuard } from "./components/guards/SuperAdminGuard";
import SettingLayout from "./pages/minimalart/settings/layout/SettingLayout";
import { SuspensivePointsLoader } from "./components/mco/components/McoLoaders";
import { MailReport } from "./pages/minimalart/reports/MailReport";
import { SegmentLayout } from "./pages/minimalart/products-and-stock/layouts/SegmentLayout";
import { SegmentsTable } from "./pages/minimalart/products-and-stock/SegmentsTable";
import { AdminGuard } from "./components/guards/AdminGuard";
import { BenefitsDetails } from "./pages/minimalart/beneficios/BenefitsDetails";
import { BenefitsLayout } from "./pages/minimalart/beneficios/layout/BenefitsLayout";
import { BenefitsOverview } from "./pages/minimalart/beneficios/BenefitsOverview";
import { MixBoxesReport } from "./pages/minimalart/reports/MixBoxesReport";
import { BankingBenefits } from "./pages/minimalart/beneficios/BankingBenefits";
import { MassiveBenefits } from "./pages/minimalart/beneficios/MassiveBenefits";
import CorporativesBenefits from "./pages/minimalart/beneficios/CorporativesBenefits";
import { BenefitsOrderDetails } from "./pages/minimalart/beneficios/BenefitsOrderDetails";


const Cron = lazy(() => import(/* webpackChunkName: "Crones" */'./pages/minimalart/settings/Crones'));
const Month = lazy(() => import(/* webpackChunkName: "Ultimo_mes" */'./pages/minimalart/settings/Mes'));
const Users = lazy(() => import(/* webpackChunkName: "Users" */'./pages/minimalart/settings/Users'));


// Routes
const routes = [

  // MCO PATHS:
  {
    path: "/",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: (
            <McoDashboard />
        ),
      },
    ],
  },
  {
    path: "/customers-overview",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "",
        element: (<McoCustomersOverview />)
      }
    ]
  },
  {
    path: "/benefits",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/benefits/overview",
        element:
          <Suspense fallback={<span>loading...</span>} >
            <BenefitsLayout>
              <BenefitsOverview />
            </BenefitsLayout>
          </Suspense>
      },
      {
        path: "/benefits/corporatives",
        element:(
          <Suspense fallback={<span>loading...</span>} >
            <BenefitsLayout>
              <CorporativesBenefits />
            </BenefitsLayout>
          </Suspense>)
      },
      {
        path: "/benefits/corporatives/employees-detail/:employee_name/:role",
        element: (
          <AuthGuard>
            <McoEmployeesOrdersDetails /> 
          </AuthGuard>
        )
      },
      {
        path: "/benefits/corporatives/customers-employees/:role/:employee_name",
        element: (
          <AuthGuard>
            <McoEmployeesList />
          </AuthGuard>
          )
      },
      {
        path: "/benefits/massives",
        element:
          <Suspense fallback={<span>loading...</span>} >
            <BenefitsLayout>
              <MassiveBenefits />
            </BenefitsLayout>
          </Suspense>
      },
      {
        path: "/benefits/massives/details/:subGroup",
        element: (
          <AuthGuard>
           <BenefitsLayout>
            <BenefitsOrderDetails />
           </BenefitsLayout>
          </AuthGuard>
          )
      },
      {
        path: "/benefits/banking-benefits",
        element:
          <Suspense fallback={<span>loading...</span>} >
            <BenefitsLayout>
              <BankingBenefits />
            </BenefitsLayout>
          </Suspense>
      },
      {
        path: "/benefits/banking-benefits/details/:subGroup",
        element: (
          <AuthGuard>
             <BenefitsLayout>
                <BenefitsOrderDetails />
             </BenefitsLayout>
          </AuthGuard>
          )
      },
      {
        path: "/benefits/details",
        element:
        <BenefitsLayout>
          <BenefitsDetails />
        </BenefitsLayout>
      },
     
    ]
  },
  {
    path: "/reports",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [ 
      {
        path: "/reports/trade-report",
        element:
         <BusinessReports />
      },
      {
        path: "/reports/buy-x-get-y",
        element:
         <BuyXgetYReports/>
      },
      {
        path: "/reports/simple",
        element:
         <SimpleReports />
      },
      {
        path: "/reports/mix-boxes",
        element:
          <Suspense fallback={<span>loading...</span>} >
              <MixBoxesReport />
          </Suspense>
      },
      {
        path: "/reports/users-mails",
        element:(
          <MailReport />
        )
      }
    ]
  },
  {
    path: "/customers-orders",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "",
        element: <McoInConstructionPage />
      }
    ]
  },
  {
    path: "/products-overview",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/products-overview/segments",
        element: 
        <ProductsProvider>
          <SegmentLayout>
           <SegmentsOverview/>
          </SegmentLayout>
        </ProductsProvider>
      },
      {
        path: "/products-overview/segments-table",
        element: 
        <ProductsProvider>
          <SegmentLayout>
            <SegmentsTable />
          </SegmentLayout>
        </ProductsProvider>
      },
      {
        path: "/products-overview/business",
        element: 
        <ProductsProvider>
            <BusinessOverview/>
        </ProductsProvider>
      }
    ]
  },
  {
    path: "/mb-sales",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/mb-sales/customers",
        element: 
        <ProductsProvider>
          <MBDistributors/>
        </ProductsProvider>
      },
      {
        path: "/mb-sales/distributors",
        element: 
        <ProductsProvider>
          <MBCustomers/>
        </ProductsProvider>
      }
    ]
  },
  {
    path: "/mb-beneficts",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/mb-beneficts/mb-beneficts-summary",
        element: 
        <ProductsProvider>
          <MBBenefictsSummary/>
        </ProductsProvider>
      },
      {
        path: "/mb-beneficts/mb-beneficts-corporate",
        element: 
        <ProductsProvider>
          <MBBenefictsCorporate/>
        </ProductsProvider>
      },
      {
        path: "/mb-beneficts/mb-beneficts-bank",
        element: 
        <ProductsProvider>
          <MBBenefictsBank/>
        </ProductsProvider>
      },
      {
        path: "/mb-beneficts/mb-beneficts-masive",
        element: 
        <ProductsProvider>
          <MBBenefictsMasive/>
        </ProductsProvider>
      }
    ]
  },
  {
    path: "/mb-reports",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/mb-reports",
        element: (<MBReports />)
      }
    ]
  },
  {
    path: "/mb-users",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/mb-users",
        element: (<MBUsersSummary />)
      }
    ]
  },
  {
    path: "/products-sales-ranking",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "",
        element: 
        <ProductsProvider>
          <McoAllProducts />
        </ProductsProvider>
      }
    ]
  },
  {
    path: "/distributors",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/distributors/distributors-overview/",
        element:
        <DistributorsProvider>
          <McoDistributorsOverview />
        </DistributorsProvider>
      },
      {
        path: "/distributors/distributors-details/:dist_id",
        element:
        <DistributorsProvider>
          <DistributorPage/>
        </DistributorsProvider>
      }
    ]
  },
  {
    path: "/distributors",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "/distributors/distributors-details",
        element:
        <DistributorsProvider>
          <McoDistributors />
        </DistributorsProvider>
      },
    ]
  },
  {
    path: "/distributors-stock-ranking",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "",
        element: <McoInConstructionPage />
      },
    ]
  },
  {
    path: "/discounts",
    element: (
      <AuthGuard>
        <McoDashboardLayout />
      </AuthGuard>),
    children: [
      {
        path: "",
        element: <McoDiscounts />
      }
    ]
  },
  {
    path: "/settings",
    element: (
      <AuthGuard>
        <SuperAdminGuard>
          <McoDashboardLayout /> 
        </SuperAdminGuard>
      </AuthGuard>),
    children:[
      {
        path: "/settings/crones",
        element: (
          <SettingLayout>
            <SuperAdminGuard>
              <Suspense fallback={ <SuspensivePointsLoader /> }>
                <Cron/>
              </Suspense>
            </SuperAdminGuard>
          </SettingLayout>
        ),
      },
      {
        path: "/settings/last_month",
        element: (
          <SettingLayout>
            <SuperAdminGuard>
              <Suspense fallback={ <SuspensivePointsLoader /> }>
                <Month/>
              </Suspense>
            </SuperAdminGuard>
          </SettingLayout>
        ),
      },
      {
        path: "/settings/users",
        element: (
          <SettingLayout>
            <SuperAdminGuard>
              <Suspense fallback={ <SuspensivePointsLoader /> }>
                <Users/>
              </Suspense>
            </SuperAdminGuard>            
          </SettingLayout>
        ),
      }
    ]
  },

  // THEME PATHS

  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
