import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

const MBBenefictsMasive = () => {

  return (
    <React.Fragment>
      <Helmet title="Resumen" />
      <Container fluid className="p-0 mco-fade-in">
        <Row>
          <Col lg="12" className="mt-2">
            {/* <iframe
              src="https://minimalartco.metabaseapp.com/public/dashboard"
              frameborder="0"
              width="100%"
              height="1920"
              allowtransparency
            ></iframe> */}
            <div>beneficios - masivos 🚧</div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MBBenefictsMasive;
