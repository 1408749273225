import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

const MBCustomers = () => {


  return (
    <React.Fragment>
      <Helmet title="Clientes" />
      <Container fluid className="p-0 mco-fade-in">
        <Row>
          <Col lg="12" className="mt-2">
            <iframe
              src="https://minimalartco.metabaseapp.com/public/dashboard/00ca531e-18a5-435a-9dfe-da3b11f47dae"
              frameborder="0"
              width="100%"
              height="1920"
              allowtransparency
            ></iframe>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MBCustomers;
