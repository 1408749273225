import { createContext, Fragment } from "react"
import { Helmet } from "react-helmet-async"
import { useSelectDate } from "../../../../hooks/useSelectDate"
import { Button, Card, Col, Container, Row } from "react-bootstrap"
import DateSelector from "../../../../components/mco/components/DateSelector"
import { Link, useLocation } from "react-router-dom"

export const SegmentContext = createContext();
const { Provider } = SegmentContext;

export const SegmentLayout = ({ children }) => {


  const [currentMonth, handleCurrentMonth, selectValues] = useSelectDate();
  const { pathname } = useLocation();

  const segmentRoutes = [
    {
      path: "/products-overview/segments",
      name: "Graficas"
    },
    {
      path: "/products-overview/segments-table",
      name: "Tabla"
    }
  ];

  return (
    <Fragment>
      <Helmet title="Segmentos" />
      <Row className="mb-2 mb-xl-3 d-flex justify-content-between">
        <Col xs="auto" className="d-none d-sm-block fs-1">
          <h3>
            Resumen de Segmentos
          </h3>
        </Col>
        <Col xs="auto" className="d-none d-sm-block fs-1">
          {
            segmentRoutes.map(route => (
              <Link to={route.path} className={`me-2 btn btn-outline-${pathname === route.path ? "info" : "secondary"}`}>
                {route.name}
              </Link>
            ))
          }
        </Col>
        <DateSelector
          selectValues={selectValues}
          handleCurrentMonth={handleCurrentMonth}
        />
      </Row>
      <Provider value={{ currentMonth }}>
        <Container fluid className="p-0 mco-fade-in">
          {children}
        </Container>
      </Provider>
    </Fragment>
  )
}
