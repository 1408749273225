import { React, Fragment, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useReports from '../../../../hooks/useReports';
import SimpleCard from './SimpleCard';

const SimpleCards = ({ period }) => {

	const {
		loading,
		simpleData,
		getSimpleData
	} = useReports();

	useEffect(() => {
		getSimpleData(period);
	}, [period]);

	return (
		<Fragment>
			<Container fluid className="p-0 mco-fade-in" >
				<Row>
					<Col sm="3" md="3" lg="3" xl="3" xxl="3" className="d-flex">
						<SimpleCard
							title="Ingresos"
							qty={simpleData[0] ? simpleData[0].total_net_revenue : 0}
							prevqty={null}
							subtitle={"Ingresos netos"}
							icon="DollarSign"
							prefix="$"
							format={true}
							is_loading={loading} />
					</Col>
					<Col sm="3" md="3" lg="3" xl="3" xxl="3" className="d-flex">
						<SimpleCard
							title="Ordenes"
							qty={simpleData[0] ? simpleData[0].total_orders_count : 0}
							prevqty={null}
							subtitle={"Compras completadas"}
							icon="ShoppingCart"
							prefix=""
							format={true}
							is_loading={loading} />
					</Col>
					<Col sm="3" md="3" lg="3" xl="3" xxl="3" className="d-flex">
						<SimpleCard
							title="Productos"
							qty={simpleData[0] ? simpleData[0].product_quantity : 0}
							prevqty={null}
							subtitle={"Unidades vendidas"}
							icon="ShoppingBag"
							prefix=""
							format={true}
							is_loading={loading} />
					</Col>
					<Col sm="3" md="3" lg="3" xl="3" xxl="3" className="d-flex">
						<SimpleCard
							title="Usuarios"
							qty={simpleData[0] ? simpleData[0].total_users_buy : 0}
							prevqty={null}
							subtitle={"Compradores únicos"}
							icon="Users"
							prefix=""
							format={true}
							is_loading={loading} />
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
}

export default SimpleCards;